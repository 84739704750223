import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/webapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/webapp/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/webapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/webapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin__pU5O5C_VbsaGGSmb4VRbMHXmeLBDloAvAuUQb8JGdU from "/webapp/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import click_outside_7Ete6GsxihCgIXTmN1TVZ5af0QxYrSD2lJ6KJRmRU_M from "/webapp/plugins/click-outside.js";
import error_fix_jaWJtj5yIZhawvXxqN9thXouxzU2Cz3w4_0xo1fXbBw from "/webapp/plugins/error-fix.js";
import fathom_client_g9EcOmElIu2_UHq7L6wiccj3U_zmPhLxdxKhC3ARoQI from "/webapp/plugins/fathom.client.js";
import notifications_lhZdQf_3Vzr6hb0QYs3vUCjjeVVJnmOVeTC5_KHmkTA from "/webapp/plugins/notifications.js";
import tags_gUok9FcaY_lE6eAEWAFk_WVlujwQmVVsPlK236bXDJE from "/webapp/plugins/tags.js";
import user_client_TIHU7d0qTiIHJ2cMl3Q7iuvBnkiFqsivPxB_ce9Z0mk from "/webapp/plugins/user.client.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin__pU5O5C_VbsaGGSmb4VRbMHXmeLBDloAvAuUQb8JGdU,
  click_outside_7Ete6GsxihCgIXTmN1TVZ5af0QxYrSD2lJ6KJRmRU_M,
  error_fix_jaWJtj5yIZhawvXxqN9thXouxzU2Cz3w4_0xo1fXbBw,
  fathom_client_g9EcOmElIu2_UHq7L6wiccj3U_zmPhLxdxKhC3ARoQI,
  notifications_lhZdQf_3Vzr6hb0QYs3vUCjjeVVJnmOVeTC5_KHmkTA,
  tags_gUok9FcaY_lE6eAEWAFk_WVlujwQmVVsPlK236bXDJE,
  user_client_TIHU7d0qTiIHJ2cMl3Q7iuvBnkiFqsivPxB_ce9Z0mk
]