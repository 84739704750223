import { notify } from '@kyvg/vue3-notification';

export function titleEncode(url_title, custom_title = null) {
    if (custom_title) {
        return custom_title;
    }
    if (!url_title) {
        return "";
    }
    return url_title.replace(/[^a-zA-Z0-9\_\.]/g, "");
}

export function getTagURLName(tag) {
    return tag
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("");
}

export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');  // $& means the whole matched string
}

export function tagsPreventSubmit(e) {
    // if enter is pressed, don't submit
    if (e.key === "Enter" || e.keyCode === 13) {
        e.preventDefault();
        return false;
    }
}

export function sanitizeBanner(banner_url) {
    if (!banner_url) {
        return banner_url;
    }
    if (banner_url.indexOf('/api') !== -1) {
        banner_url = banner_url.replace('/api', '')
    }
    banner_url = banner_url
        .replace('/banner/', '/banners/')
        .replace('www.minecraftiplist.com', 'static.minecraftiplist.com')
    if (banner_url.indexOf('minecraftiplist.com') === -1) {
        banner_url = 'https://static.minecraftiplist.com' + banner_url;
    }

    return banner_url;
}

export async function copyIP(ip) {
    if (!import.meta.client) return;

    try {
        $fetch("/api/ipcopy", {
            method: "POST",
            body: {
                ip: ip,
            },
            ignoreResponseError: true,
        });
    } catch (e) {
    }

    try {
        await navigator.clipboard.writeText(ip);
        return true;
    } catch (error) {
        console.error("Failed to copy to clipboard!", error);
        notify({
            type: "error",
            title: "Failed to copy to clipboard!",
        })
        return false;
    }
}

export function isMobile(userAgent) {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

    if (regex.test(userAgent)) {
        return true;
    } else {
        return false;
    }
}

export function getPlatform(server) {
    let platform = 0;
    if (server.status && server.status.uptime) {
        platform++;
    }
    if (server.bedrock_ip && server.bedrock_status && server.bedrock_status.uptime) {
        platform += 2;
    }
    if (platform === 0) {
        platform = 1;
    }
    return platform;
}

export function encodeHTML(str) {
    try {
        return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');
    } catch (e) {
        return str;
    }
}

export function getFilter(pageName) {
    switch(pageName) {
        case "search-page":
            return "search";
        case "tag-page":
            return "tag";
        case "my-servers-page":
            return "user";
        case "favourites-page":
            return "favourites";
        default:
            return "top";
    }
}