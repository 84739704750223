
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index0NXWnsvWegfigNnrD6u2R0NKQVza6xPyYEVRSQF4cxgMeta } from "/webapp/pages/admin/index.vue?macro=true";
import { default as logswN3RAhTQGhNHaKU_45VHG21OFFslVtnSdrk_SbcyPOvE8Meta } from "/webapp/pages/admin/logs.vue?macro=true";
import { default as servers2AuidxbWCYIZA5RKxXDP_1edL3NM90kn2jHT1I1CEEsMeta } from "/webapp/pages/admin/servers.vue?macro=true";
import { default as usersE3wCl4n_0dLpL4Mm47U8JpWz_45r0sWBv5GKc1hdVrNAEMeta } from "/webapp/pages/admin/users.vue?macro=true";
import { default as contact2ENz9TT4OzLPIcwrdh2qoiSkRGbwjhoEws8gjWuc7uIMeta } from "/webapp/pages/contact.vue?macro=true";
import { default as indextpuxdz4SIpJWjxXGpYT0YBIwt9gk_45CAxS4mopKkq_457oMeta } from "/webapp/pages/index.vue?macro=true";
import { default as loginlA5Wot7wTaOLMrYydzTYx2QATBmxKh0jCXTGW7J24jAMeta } from "/webapp/pages/login.vue?macro=true";
import { default as index4S_ZgEcOhfC61C_AeMOGMmLKFd9ODiEu3lU_4_45xbsj0Meta } from "/webapp/pages/password-reset/[id]/index.vue?macro=true";
import { default as _91_91page_93_9304fyF9C_NB_45T6SatBuMVt_45Z4L_ekQW1kbEVhYI8S_45D4Meta } from "/webapp/pages/rank/[[page]].vue?macro=true";
import { default as _91_91page_93_93SmI29Y65m8ZVuz8fEYwKSRqwMmEWAGalogJ9fLTtpzwMeta } from "/webapp/pages/search/[search]/[[page]].vue?macro=true";
import { default as _91_91page_93_93J_45NtISrS_UzBZi_jhb0Vly2h_45flp1y0FrjXUdHZTdVwMeta } from "/webapp/pages/server-tags/[tag]/[[page]].vue?macro=true";
import { default as editIM9hVejuvW2h3fPD4tT1G_iqT5W9Et6AOEu_gRMXTdQMeta } from "/webapp/pages/server/[id]/edit.vue?macro=true";
import { default as indexuzF0KTTw_if7G5R_45zePyT3Z36hovjxJj2GXN1CuSyUMMeta } from "/webapp/pages/server/[id]/index.vue?macro=true";
import { default as voteEsGuLcfS4cwe6FLGmQ0ZNOhsiXtNvm_af6E_N7WNsyAMeta } from "/webapp/pages/server/[id]/vote.vue?macro=true";
import { default as addhkpc9qEmuUYlgBQ3i0wa4N6hN_ChKGDRfI8be8TAaVsMeta } from "/webapp/pages/server/add.vue?macro=true";
import { default as _91_91page_93_93WYNQ_4562_6x2Y8xUGNWk_DH0l18UXaRZwZloZG_45COi7YMeta } from "/webapp/pages/server/favourite/[[page]].vue?macro=true";
import { default as _91_91page_93_93iYXuBd9nx_45f2_45BtRJSfWCHZohQZS_z9dSrERTLNiS6wMeta } from "/webapp/pages/server/my/[[page]].vue?macro=true";
import { default as reuploadVZ4l7Wp8blNPZS_aw9KonmHHoSzEAOyx4u0f_457qWoZMMeta } from "/webapp/pages/server/reupload.vue?macro=true";
import { default as terms_45and_45conditionsS0fteFF3PCvK_45XGOLh5Qt_Ir9EpzIqWwm7N9IaV9c5sMeta } from "/webapp/pages/terms-and-conditions.vue?macro=true";
import { default as component_45stubiRucU2y3r0IBuqGPMVxXo_k0rBcE1K4eXZj1xuSA2mAMeta } from "/webapp/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubiRucU2y3r0IBuqGPMVxXo_k0rBcE1K4eXZj1xuSA2mA } from "/webapp/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin",
    path: "/admin",
    component: () => import("/webapp/pages/admin/index.vue")
  },
  {
    name: "admin-logs",
    path: "/admin/logs",
    component: () => import("/webapp/pages/admin/logs.vue")
  },
  {
    name: "admin-servers",
    path: "/admin/servers",
    component: () => import("/webapp/pages/admin/servers.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/webapp/pages/admin/users.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/webapp/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indextpuxdz4SIpJWjxXGpYT0YBIwt9gk_45CAxS4mopKkq_457oMeta || {},
    component: () => import("/webapp/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginlA5Wot7wTaOLMrYydzTYx2QATBmxKh0jCXTGW7J24jAMeta || {},
    component: () => import("/webapp/pages/login.vue")
  },
  {
    name: "password-reset-id",
    path: "/password-reset/:id()",
    component: () => import("/webapp/pages/password-reset/[id]/index.vue")
  },
  {
    name: "rank-page",
    path: "/rank/:page?",
    meta: _91_91page_93_9304fyF9C_NB_45T6SatBuMVt_45Z4L_ekQW1kbEVhYI8S_45D4Meta || {},
    component: () => import("/webapp/pages/rank/[[page]].vue")
  },
  {
    name: "search-page",
    path: "/search/:search()/:page?",
    meta: _91_91page_93_93SmI29Y65m8ZVuz8fEYwKSRqwMmEWAGalogJ9fLTtpzwMeta || {},
    component: () => import("/webapp/pages/search/[search]/[[page]].vue")
  },
  {
    name: "tag-page",
    path: "/server-tags/:tag()/:page?",
    meta: _91_91page_93_93J_45NtISrS_UzBZi_jhb0Vly2h_45flp1y0FrjXUdHZTdVwMeta || {},
    component: () => import("/webapp/pages/server-tags/[tag]/[[page]].vue")
  },
  {
    name: "server-id-edit",
    path: "/server/:id()/edit",
    component: () => import("/webapp/pages/server/[id]/edit.vue")
  },
  {
    name: "server-id",
    path: "/server/:id()",
    component: () => import("/webapp/pages/server/[id]/index.vue")
  },
  {
    name: "server-id-vote",
    path: "/server/:id()/vote",
    component: () => import("/webapp/pages/server/[id]/vote.vue")
  },
  {
    name: "server-add",
    path: "/server/add",
    component: () => import("/webapp/pages/server/add.vue")
  },
  {
    name: "favourites-page",
    path: "/server/favourite/:page?",
    meta: _91_91page_93_93WYNQ_4562_6x2Y8xUGNWk_DH0l18UXaRZwZloZG_45COi7YMeta || {},
    component: () => import("/webapp/pages/server/favourite/[[page]].vue")
  },
  {
    name: "my-servers-page",
    path: "/server/my/:page?",
    meta: _91_91page_93_93iYXuBd9nx_45f2_45BtRJSfWCHZohQZS_z9dSrERTLNiS6wMeta || {},
    component: () => import("/webapp/pages/server/my/[[page]].vue")
  },
  {
    name: "server-reupload",
    path: "/server/reupload",
    component: () => import("/webapp/pages/server/reupload.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/webapp/pages/terms-and-conditions.vue")
  },
  {
    name: component_45stubiRucU2y3r0IBuqGPMVxXo_k0rBcE1K4eXZj1xuSA2mAMeta?.name,
    path: "/mcuser:",
    component: component_45stubiRucU2y3r0IBuqGPMVxXo_k0rBcE1K4eXZj1xuSA2mA
  },
  {
    name: component_45stubiRucU2y3r0IBuqGPMVxXo_k0rBcE1K4eXZj1xuSA2mAMeta?.name,
    path: "/mcuser/:pathMatch(.*)",
    component: component_45stubiRucU2y3r0IBuqGPMVxXo_k0rBcE1K4eXZj1xuSA2mA
  },
  {
    name: component_45stubiRucU2y3r0IBuqGPMVxXo_k0rBcE1K4eXZj1xuSA2mAMeta?.name,
    path: "/nsearch",
    component: component_45stubiRucU2y3r0IBuqGPMVxXo_k0rBcE1K4eXZj1xuSA2mA
  },
  {
    name: component_45stubiRucU2y3r0IBuqGPMVxXo_k0rBcE1K4eXZj1xuSA2mAMeta?.name,
    path: "/nsearch/:pathMatch(.*)",
    component: component_45stubiRucU2y3r0IBuqGPMVxXo_k0rBcE1K4eXZj1xuSA2mA
  }
]