import { useAsyncData } from "#app";

export default defineNuxtPlugin({
	parallel: true,
	async setup() {
		const {
			currentTag,
			displayTags,
			displayTagsStatus,
			popularTags,
			randomTags,
		} = useTags();

		const route = useRoute();

		const { data: tagsData, status: tagsStatus } = useAsyncData('tags_data', () => $fetch("/api/tag", {
			query: {
				limit: 25,
			},
			ignoreResponseError: true
		}));

		watch(() => tagsStatus.value, (val) => {
			if (displayTags.value === tagsData.value.popularTags) return;
			if (val === "success") {
				popularTags.value = tagsData.value.popularTags;
				randomTags.value = tagsData.value.randomTags;
				displayTags.value = tagsData.value.popularTags;
				displayTagsStatus.value = true;
			} else if (val === "error") {
				displayTags.value = popularTags.value;
				displayTagsStatus.value = true;
			} else {
				displayTagsStatus.value = false;
			}
		});

		watch(() => route.fullPath, async (val, val2) => {
			const oldPageWithoutPage = val.replace(/\/(\d+)$/, '');
			const newPageWithoutPage = val2.replace(/\/(\d+)$/, '');
			if (oldPageWithoutPage === newPageWithoutPage) return;
			if (!route.params.tag) {
				displayTags.value = popularTags.value;
			} else {
				displayTagsStatus.value = false;
			}
		});

		let abortController = new AbortController();

		watch(() => route.params.tag, () => {
			if (abortController) {
				abortController.abort();
			}
			abortController = new AbortController();

			if (route.params.tag) {
				refresh();
			}
		});

		const {
			data: tagData,
			refresh,
		} = await useAsyncData(`current_tag`, async () => {
			if (!route.params.tag) {
				return;
			}
			return $fetch("/api/tag/search", {
				query: {
					search: route.params.tag,
					exact: true,
					populateSimilar: true,
					limit: 1
				},
				signal: abortController.signal
			})
		}, {
			transform: (data) => {
				if (!data || !data.length) {
					return null;
				}

				currentTag.value = data[0];

				if (data[0].similar) {
					displayTags.value = data[0].similar;
					displayTagsStatus.value = true;
				}

				return data[0];
			},
		});

		if (!tagData.value && route.params.tag) {
			throw createError({
				statusCode: 404,
				message: "Tag not found"
			});
		}
	}
});

