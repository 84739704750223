<template>
	<div class="bg-image" v-if="showBackgroundImage">
		<div class="relative w-full h-full overflow-hidden">
			<div class="bg-image-inner"/>
		</div>
	</div>
</template>

<script setup>
const route = useRoute();

const showBackgroundImage = ref(false);

function checkBackgroundImage() {
	showBackgroundImage.value = !/server\/(.*-\d+|my|.*\/edit|add)|admin|terms-and-conditions.*/g.test(route.path);
}

watch(() => route.path, () => {
	checkBackgroundImage();
});

onMounted(() => {
	checkBackgroundImage();
})
</script>

<style>
.bg-image {
    position: absolute;
    top: -11%;
    left: 0;
    width: 100%;
    height: 55vh;
    z-index: -4;

    background: linear-gradient(180deg, #90c1fc 0%, #90c1fc 20%, white 100%);
}

.bg-image-inner {
    background-image: url('/images/clouds.png');
    background-size: contain;
    background-repeat: repeat;
    animation: moveToRight 100000s linear infinite;
    background-position: 0 0;
    z-index: -3;
    position: absolute;
    top: 0;
    left: 0;
    width: 2000000px;
    height: 100%;
}

.bg-image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(245, 245, 244, 0) 40%, rgba(245, 245, 244, 1)) no-repeat;
    background-size: cover;
    z-index: 0;
}
</style>