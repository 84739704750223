<template>
	<div class="page">
		<BackgroundImage />
		<Navbar/>
		<NuxtLoadingIndicator color="#38a169" :height="3" :duration="1000" :throttle="0"/>
		<div class="page-content  pt-32 m-lg:pt-24"
		     :class="{
				'blur-xs': !!popup,
		     }">
			<NuxtLayout>
				<NuxtPage/>
			</NuxtLayout>
			<div class="footer mx-4">
				<FooterFAQ v-if="!hiddenFooter"/>
				<FooterPages v-if="!hiddenFooter"/>
				<Footer/>
			</div>
		</div>
		<Transition name="popup" v-if="popup === 'ForgotPasswordPopup'">
			<LazyPopupsForgotPasswordPopup v-if="popup === 'ForgotPasswordPopup'"/>
		</Transition>
		<Transition name="popup" v-else-if="popup === 'LoginPopup'">
			<LazyPopupsLoginPopup v-if="popup === 'LoginPopup'"/>
		</Transition>
		<Transition name="popup" v-else-if="popup === 'RegisterPopup'">
			<LazyPopupsRegisterPopup v-if="popup === 'RegisterPopup'"/>
		</Transition>
		<Transition name="popup" v-else-if="popup === 'UserSettingsPopup'">
			<LazyPopupsUserSettingsPopup v-if="popup === 'UserSettingsPopup'"/>
		</Transition>
		<Transition name="popup" v-else-if="popup === 'VotePopup'">
			<LazyPopupsVotePopup v-if="popup === 'VotePopup'"/>
		</Transition>
		<Transition name="popup" v-else-if="popup === 'AdminBlogImagesPopup'">
			<LazyAdminBlogImagesPopup v-if="popup === 'AdminBlogImagesPopup'"/>
		</Transition>
	</div>

	<div>
		<Transition name="go-up">
			<LazyGoUp v-if="showGoUp"/>
		</Transition>
	</div>

	<notifications position="bottom right" :dangerously-set-inner-html="true" animation-type="css" width="auto"/>
</template>

<script setup>
import { notify } from "@kyvg/vue3-notification";

import("~/assets/css/hint.min.css");

const { popup, setPopup } = usePopup();

const route = useRoute();

const hiddenFooter = computed(() => route.path.startsWith("/server/"));

watch(
	() => route.path,
	(currentRoute) => {
		if (!import.meta.client) return;

		if (!/\/vote$/.test(currentRoute)) {
			setPopup(null);
		}

		const toastCookie = useCookie("toast");
		if (!toastCookie.value) return;

		const { type, title } = toastCookie.value;
		notify({ type, title });
		toastCookie.value = null;

		if (
			type === "error" &&
			(title.includes("login") || title.includes("logged"))
		) {
			setPopup("LoginPopup");
		}
	}
);

useSeoMeta({
	title: "Minecraft Server List - Find Minecraft Multiplayer Servers",
	ogTitle: "Minecraft Server List - Find Minecraft Multiplayer Servers",
	keywords: "minecraft ip list, minecraft server list, minecraft servers, minecraft ip addresses, minecraft server ip addresses list, minecraft multiplayer",
	description: "Find minecraft multiplayer servers IP/address on our Minecraft server list. Search for your favourite type of multiplayer server here.",
	ogDescription: "Find minecraft multiplayer servers IP/address on our Minecraft server list. Search for your favourite type of multiplayer server here.",
	lang: "en",
});

useHead({
	link: [
		{
			rel: "canonical",
			href: "https://wwww.minecraftiplist.com" + route.path
		},
	],
	htmlAttrs: {
		lang: "en",
	},
});


const showGoUp = ref(false);

onMounted(() => {
	// Go Up Button
	showGoUp.value = window.scrollY > 300;
	document.addEventListener("scroll", () => {
		showGoUp.value = window.scrollY > 300;
	});
});
</script>

<style>
html,
body {
    margin: 0;
    padding: 0;
}

body {
    background: #f5f5f4;
}

html,
body,
a,
button,
p,
span {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

* {
    transition: all 0.35s ease-in-out;
}

@keyframes moveToRight {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.page-content {
    width: min(1250px, 100%);
    margin: 0 auto;
    z-index: 10;

    @media (max-width: 1536px) and (min-width: 1200px) {
        width: min(1250px, 100%);
    }

    @media (max-width: 1200px) and (min-width: 1024px) {
        width: 97%;
    }
}

.go-up-enter-active,
.go-up-leave-active {
    transition: all 0.4s ease-in-out;
}

.go-up-enter-from,
.go-up-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}

video::-webkit-media-controls-start-playback-button {
    display: none;
}

.vue-notification {
    border-radius: 8px;
    border-left-width: 7px !important;

    .notification-title {
        font-size: .9rem;
    }

    .notification-content {
        font-size: .8rem;
    }
}

.is-correct {
    position: absolute;
    width: 16px;
    height: 16px;

    top: 50%;
    transform: translateY(-50%);
    right: 0.5em;
    transition: none;
}

.is-correct--true {
    background: url("/icons/tick.svg") no-repeat center;
    background-size: 16px;
    filter: invert(80%) sepia(46%) saturate(5893%) hue-rotate(74deg) brightness(103%) contrast(78%);
}

.is-correct--false {
    position: absolute;
    width: 16px;
    height: 16px;

    background: url("/icons/cross.svg") no-repeat center;
    background-size: 20px;
    filter: invert(25%) sepia(89%) saturate(6900%) hue-rotate(356deg) brightness(99%) contrast(118%);
}

.popup-enter-active,
.popup-leave-active {
    transition: opacity 0.35s ease-in-out;
}

.popup-enter-from,
.popup-leave-to {
    opacity: 0;
}
</style>