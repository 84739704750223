<template>
	<NuxtLink no-prefetch active-class="active-page" to="/" @click="closeNav"
	          class="bg-white-100 border-2 border-gray-200 transition-none hover:border-green-800 flex items-center gap-2 h-fit px-3 py-2 rounded-lg whitespace-nowrap m-lg:w-full"
	          activeClass="border-green-800">
		<img alt="Home icon" src="/icons/home.svg" width="20" height="20">
		Home
	</NuxtLink>
	<NuxtLink no-prefetch active-class="active-page" href="/blog/" :external="true" @click="closeNav"
	          class="bg-white-100 border-2 border-gray-200 transition-none hover:border-green-800 flex items-center gap-2 h-fit px-3 py-2 rounded-lg whitespace-nowrap m-lg:w-full">
		<img alt="Tag icon" src="/icons/info.svg" width="20" height="20">
		Blog
	</NuxtLink>
	<div
			class="tagged-servers  m-lg:w-full"
			@mouseenter="toggleTaggedServersHover(true)" @mouseleave="toggleTaggedServersHover(false)"
	>
		<button class="bg-white-100 border-2 border-gray-200 transition-none hover:border-green-800 relative flex items-center gap-2 h-fit px-3 py-2 rounded-lg whitespace-nowrap
					   m-lg:w-full"
		        @click="toggleTaggedServers"
		        :class="{ 'border-green-800': taggedServers }"
		>
			<img alt="Tagged Servers icon" src="/icons/tag.svg" width="20" height="20">
			Tagged Servers
			<img alt="Arrow pointing to the right" src="/icons/right_arrow_small.svg" width="12" height="12"
			     class="absolute right-3 lg:hidden"
			     :class="{ 'rotate-90': taggedServers }"/>
		</button>
		<Transition name="tagged-servers">
			<div v-if="taggedServers"
			     class="tagged-servers-data  absolute top-full flex flex-wrap bg-white-300 p-2 gap-2 z-10 rounded-b-xl
			            lg:shadow-2xl m-lg:relative m-lg:left-0 m-lg:top-0 m-lg:right-0 m-lg:w-full">
				<div class="w-full text-start text-lg cursor-default">Popular Tags</div>
				<NuxtLink v-if="popularTags" v-for="tag in popularTags" :key="tag.name"
				          :to="`/server-tags/${tag.urlname}`" @click="closeNav"
				          class="p-1 bg-gray-100 border-2 border-gray-200 rounded-lg
								 hover:bg-gray-400">
					{{ tag.name }}
				</NuxtLink>
				<div class="w-full text-start text-lg cursor-default">Random Tags</div>
				<NuxtLink v-if="randomTagsData" v-for="tag in randomTagsData.randomTags" :key="tag.name"
				          :to="`/server-tags/${tag.urlname}`" @click="closeNav"
				          class="p-1 bg-gray-100 border-2 border-gray-200 rounded-lg
								 hover:bg-gray-400">
					{{ tag.name }}
				</NuxtLink>
			</div>
		</Transition>
	</div>
	<NuxtLink no-prefetch to="https://skinsmc.org/" target="_blank"
	          class="bg-white-100 border-2 border-gray-200 transition-none hover:border-green-800 flex items-center gap-2 h-fit px-3 py-2 rounded-lg whitespace-nowrap m-lg:w-full ">
		<img alt="Minecraft Skins shirt icon" src="/icons/tshirt.svg" width="20" height="20">
		Minecraft Skins
	</NuxtLink>
	<NuxtLink no-prefetch active-class="active-page" to="/contact" @click="closeNav"
	          class="bg-white-100 border-2 border-gray-200 transition-none hover:border-green-800 flex items-center gap-2 h-fit px-3 py-2 rounded-lg whitespace-nowrap m-lg:w-full "
	          activeClass="border-green-800">
		<img alt="Chat bubble icon" src="/icons/bubble.svg" width="20" height="20">
		Contact Us
	</NuxtLink>
</template>

<script setup>
const props = defineProps({
	closeNav: Function
});

const taggedServers = ref(false);

function toggleTaggedServers() {
	if (window.innerWidth > 1024) return;
	taggedServers.value = !taggedServers.value;
}

function toggleTaggedServersHover(state) {
	if (window.innerWidth < 1024) return;

	if (state) {
		taggedServers.value = true;
	} else {
		setTimeout(() => {
			const hovered = document.querySelectorAll(":hover");
			for (const el of hovered) {
				if (
					el.closest(".tagged-servers") ||
					el.classList.contains("tagged-servers")
				) {
					return;
				}
			}

			taggedServers.value = false;
		}, 300);
	}
}

const { popularTags } = useTags();

const { data: randomTagsData } = await useLazyFetch("/api/tag", {
	query: {
		limit: 20,
		random: true,
		popular: false
	},
	server: false,
})
</script>

<style scoped>
.tagged-servers-enter-active,
.tagged-servers-leave-active {
    transition: all 0.35s ease-in-out;
}

.tagged-servers-enter-from,
.tagged-servers-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}
</style>