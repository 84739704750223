import { getUserData } from "~/composables/useUser.js";

export default defineNuxtPlugin({
	parallel: true,
	async setup(nuxtApp) {

		const session = useCookie("session");
		const user = useUser();

		await callOnce(async () => {
			if (session.value) {
				const userData = await getUserData(session.value);
				if (userData) {
					user.value = userData;
					setUser(userData);
				}
			}
		});
	}
});
