export default function useTags() {
	const currentTag = useState('current_tag', () => null);
	const displayTags = useState('tags_display', () => []);
	const displayTagsStatus = useState('tags_display_status', () => false);
	const popularTags = useState('tags_popular', () => []);
	const randomTags = useState('tags_random', () => []);

	return {
		currentTag,
		displayTags,
		displayTagsStatus,
		popularTags,
		randomTags,
	}
}